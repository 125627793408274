var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "projectSelectUser" },
    [
      _c(
        "el-container",
        [
          _vm.userDeptType === "all"
            ? _c(
                "div",
                { staticClass: "UserDeptTree" },
                [
                  _c("tree-layout", {
                    staticStyle: { "margin-right": "5px" },
                    attrs: {
                      treeTitle: "组织架构",
                      treeLoading: _vm.treeLoading,
                      "tree-options": _vm.treeOptions,
                    },
                    on: {
                      "tree-node-click": _vm.getNodeClick,
                      "include-down": _vm.includeDownAll,
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("CommonTreeNew", {
                    ref: "commonTree",
                    staticClass: "UserDeptTree",
                    attrs: {
                      defaultProps: _vm.defaultProps,
                      searchTitle: _vm.searchTitle,
                      treeTitle: _vm.$t(`cip.plat.sys.user.title.treeTitle`),
                      isShowdig: false,
                      showCheckbox: false,
                      "popover-show": false,
                      urlParmas: {
                        tenantId: "",
                        parentId: _vm.userInfo.dept_id,
                        ..._vm.treeParams,
                      },
                    },
                    on: {
                      getNodeClick: _vm.getNodeClick,
                      "include-down": _vm.includeDown,
                    },
                  }),
                ],
                1
              ),
          _c(
            "el-container",
            [
              _c(
                "el-main",
                { staticClass: "margin-r-10", staticStyle: { width: "50%" } },
                [
                  _c("head-layout", {
                    attrs: {
                      "head-title": _vm.$t(
                        "cip.plat.sys.user.title.indexHeadTitle"
                      ),
                    },
                  }),
                  _c("grid-head-layout", {
                    ref: "searchFrom",
                    attrs: {
                      "head-title": _vm.$t(
                        "cip.plat.sys.user.title.indexHeadTitle"
                      ),
                      "search-columns": _vm.searchColumns,
                    },
                    on: {
                      "grid-head-search": _vm.searchChange,
                      "grid-head-empty": _vm.searchReset,
                    },
                    model: {
                      value: _vm.searchForm,
                      callback: function ($$v) {
                        _vm.searchForm = $$v
                      },
                      expression: "searchForm",
                    },
                  }),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.tableLoading,
                          expression: "tableLoading",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        border: "",
                        data: _vm.tableData,
                        stripe: "",
                        height: 447,
                        rowKey: "id",
                      },
                      on: { "selection-change": _vm.selectionChange },
                    },
                    [
                      _vm.multiple
                        ? _c("el-table-column", {
                            attrs: {
                              "reserve-selection": "",
                              selectable: _vm.selectable,
                              type: "selection",
                              width: "55",
                            },
                          })
                        : _vm._e(),
                      _vm._l(_vm.tableOptions.column, function (item, index) {
                        return _c("el-table-column", {
                          key: index,
                          attrs: {
                            width: item.width,
                            "show-overflow-tooltip": item.overHidden,
                            prop: item.prop,
                            label: item.label,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ row, $index }) {
                                  return [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          item.formatter
                                            ? item.formatter(row, $index)
                                            : row[item.prop]
                                        ),
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                      !_vm.multiple
                        ? _c("el-table-column", {
                            attrs: {
                              fixed: "right",
                              label: "操作",
                              width: "100",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.roleDefBtn(row)
                                            },
                                          },
                                        },
                                        [_vm._v("选择")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2294607480
                            ),
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c("el-pagination", {
                    staticClass: "margin-t-20",
                    attrs: {
                      background: "",
                      "current-page": _vm.page.currentPage,
                      "page-sizes": [10, 20, 30, 40, 50, 100],
                      "page-size": _vm.page.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.page.total,
                    },
                    on: {
                      "size-change": _vm.onLoad,
                      "current-change": _vm.onLoad,
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.page, "currentPage", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.page, "currentPage", $event)
                      },
                      "update:pageSize": function ($event) {
                        return _vm.$set(_vm.page, "pageSize", $event)
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(_vm.page, "pageSize", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.multiple
                ? _c(
                    "el-main",
                    {
                      staticClass: "border-left",
                      staticStyle: { width: "50%" },
                    },
                    [
                      _c("head-layout", {
                        staticClass: "margin-b-14",
                        attrs: { "head-title": "已选用户" },
                      }),
                      _c(
                        "el-table",
                        {
                          staticStyle: { "margin-top": "48px" },
                          attrs: {
                            border: "",
                            data: _vm.selectData,
                            stripe: "",
                            height: 490,
                            rowKey: "id",
                          },
                        },
                        [
                          _vm._l(
                            _vm.tableOptions.column,
                            function (item, index) {
                              return _c("el-table-column", {
                                key: index,
                                attrs: {
                                  width: item.width,
                                  "show-overflow-tooltip": item.overHidden,
                                  prop: item.prop,
                                  label: item.label,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ row, $index }) {
                                        return [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                item.formatter
                                                  ? item.formatter(row, $index)
                                                  : row[item.prop]
                                              ),
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            }
                          ),
                          _c("el-table-column", {
                            attrs: { label: "操作", fixed: "right" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ $index, row }) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "danger",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDelete(
                                                $index,
                                                row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除\n              ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              172255840
                            ),
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex-container flex-jus-e footerBtn" },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.loading,
                disabled: _vm.loading,
                size: "mini",
              },
              on: { click: _vm.closeDialog },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.loading,
                disabled: _vm.loading,
                size: "mini",
                type: "primary",
              },
              on: { click: _vm.confirm },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }